import React, { memo } from 'react'
import Layout from '../components/Layout'

const Jco = props => {
  return (
    <Layout canonicalURL='' title='' pageTitle='' description=''>
    
    </Layout>
  )
}

export default memo(Jco)